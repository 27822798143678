<template>
  <b-badge
    :variant="
      state.codename === 'delivered'
        ? 'success'
        : state.codename === 'loaded'
        ? 'warning'
        : state.codename === 'canceled'
        ? 'danger'
        : state.codename === 'verified'
        ? 'info'
        : 'primary'
    "
  >
    {{ state.name }}
  </b-badge>
</template>
<script>
import { BBadge } from "bootstrap-vue";

export default {
  components: {
    BBadge,
  },
  props: {
    state: {
      required: true,
      type: Object,
    },
  },
};
</script>
