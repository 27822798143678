import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useOrderTracking() {
  // Use toast
  const { t } = useI18nUtils();

  const refOrderListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "client.name", label: t("Client") },
    { key: "ordered_qty", label: t("t.qty ord") },
    { key: "ordered_qty_amount", label: t("t.amount ord") },
    { key: "available_qty", label: t("t.qty avai") },
    { key: "available_qty_amount", label: t("t.amount avai") },
    //{ key: "lot_qty", label: t("t.qty lot") },
    //{ key: "lot_qty_amount", label: t("t.amount lot") },
    { key: "charge.ref", label: t("ref.charge") },
    { key: "delivery_man.name", label: t("Delivery man") },
    { key: "created_at", label: t("h.crt") },
    { key: "validation_time", label: t("t.validation") },
    { key: "preparation_time", label: t("t.preparation") },
    { key: "delivered_time", label: t("t.delivery") },
    { key: "tqte_monq", label: t("t.q te monq") },
    { key: "tsale_lost", label: t("t.sale lost") },
    { key: "state", label: t("state") },
  ];
  const perPage = ref(10);
  const totalOrders = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const rangeDate = ref("");
  const clientValue = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value
      ? refOrderListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    };
  });

  const refetchData = () => {
    refOrderListTable.value.refresh();
  };

  watch([currentPage, perPage, clientValue, rangeDate], () => {
    refetchData();
  });

  const fetchOrders = (ctx, callback) => {
    const dates = rangeDate.value ? rangeDate.value.split("to") : [];

    instance
      .get("/orders/orderline-trackings/", {
        params:
          dates.length === 2
            ? {
                page: currentPage.value,
                client: clientValue.value?.id,
                start_date: dates[0].trim(),
                end_date: dates[1].trim(),
              }
            : {
                page: currentPage.value,
                client: clientValue.value?.id,
              },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalOrders.value = count;
        callback(results);
      })
      .catch((err) => {
      });
  };

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,

    sortBy,
    isSortDirDesc,
    refOrderListTable,
    refetchData,
    rangeDate,
    clientValue,
  };
}
