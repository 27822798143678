var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-badge',{attrs:{"variant":_vm.state.codename === 'delivered'
      ? 'success'
      : _vm.state.codename === 'loaded'
      ? 'warning'
      : _vm.state.codename === 'canceled'
      ? 'danger'
      : _vm.state.codename === 'verified'
      ? 'info'
      : 'primary'}},[_vm._v(" "+_vm._s(_vm.state.name)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }