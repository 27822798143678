<template>
  <component is="b-card">
    <div>
      <b-row>
        <!-- Field: Client -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
              :loading="loading"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Date')" label-for="range">
            <flat-pickr
              id="range"
              v-model="rangeDate"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-card no-body class="mb-0">
        <b-table
          id="scrolle-table"
          ref="refOrderListTable"
          class="position-relative"
          :items="fetchOrders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(state)="data">
            <custom-order-line-state-badge :state="data.item.state" />
            <!-- {{ data.item.state.name }} -->
          </template>
          <template #cell(actions)>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="ClipboardIcon" size="16" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BBadge,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useOrderLineTracking from "./useOrderLineTracking";
import Ripple from "vue-ripple-directive";
import CustomOrderLineStateBadge from "@/views/ui/CustomOrderLineStateBadge.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    CustomOrderLineStateBadge,
    flatPickr,
    BBadge,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },

  data() {
    return {
      loading:null,
      chargeDetails: [],
      trackings: [],
      clients: [],
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const res = await instance.get("/parameters/clients/");
      this.clients = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }finally {
      this.loading = false;
    }
  },
  // async
  methods: {
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      rangeDate,
      clientValue,
    } = useOrderLineTracking();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,

      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      rangeDate,
      clientValue,
    };
  },
};
</script>

<style></style>
